export const PERMISSION_MANAGEMENT='permission_management'//权限管理修改权限
export const TEMP_ADMMIN='system.admin'//临时接口权限
export const SYSTEM_ADMINISTRATOR='system_administrator'//系统管理员权限

export const BACKGROUNDMANAGEMENT='background_management'//后台管理

export const PROJECTMANGEMENT='ProjectManagement'//项目管理
export const PROJECTMANGEMENTADD='ProjectManagement.Add'//新建项目
export const PROJECTMANGEMENTDELETE='ProjectManagement.Delete'//删除项目
export const PROJECTMANGEMENTEDIT='ProjectManagement.Edit'//编辑项目





