const state = {
    elevatorPaginationData:{}
}

const mutations={
    setElevatorPaginationData(state,elevatorPaginationData){
        state.elevatorPaginationData=elevatorPaginationData;
    } 
}

export default {
    namespaced: true,
    state,
    mutations
} 