import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from '@/api/index.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import elementlocale from 'element-ui/lib/locale/lang/zh-CN';
import promise from 'es6-promise';
import i18n from './lang/index'
import './filter';
import './styles/index.scss';
import './icons/iconfont.css'
const { datafluxRum } = require('@cloudcare/browser-rum') // 初始化 Rum 
datafluxRum.init({
	datakitOrigin: location.host=='dmod.kone.cn'?'https://dmod-prod.guance.kone.cn':'https://dmod-dev.guance.kone.cn',// 必填，Datakit域名地址 需要在微信小程序管理后台加上域名白名单 
  applicationId: location.host=='dmod.kone.cn'?'ff9d57f0_81cb_11ee_9c19_e7f9b61e9a5e':'84b74520_7490_11ee_956a_157bc5a90d0f', // 必填，dataflux 平台生成的应用ID
  env: 'dev', // 选填，小程序的环境 
  version: '1.0.0', // 选填，小程序版本 
  service: 'dmod', //当前应用的服务名称 
  trackInteractions: true, 
  traceType: 'ddtrace', // 非必填，默认为ddtrace，目前支持 ddtrace、zipkin、skywalking_v3、jaeger、zipkin_single_header、w3c_traceparent 6种类型 
  sampleRate: 100, //指标数据收集的百分比，100 表示全收集，0 表示不收集 
  // allowedTracingOrigins: ['https://api.example.com', /https:\/\/.*\.my-api-domain\.com/], // 非必填，允许注入trace采集器所需header头部的所有请求列表。可以是请求的origin，也可以是是正则 
})
import {PERMISSIONCODE} from '@/config/permission'

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import './utils/validate'
import 'nprogress/nprogress.css'

Vue.config.productionTip = false;

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(ElementUI, {
  size: 'small', // set element-ui default size
  locale :elementlocale,
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(VueQuillEditor)



promise.polyfill();

Vue.prototype.$axios = axios;
Vue.prototype.PERMISSIONCODE=PERMISSIONCODE;


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
