const state = {
    versionPaginationData:{}
}

const mutations={
    setVersionPaginationData(state,versionPaginationData){
        state.versionPaginationData=versionPaginationData;
    } 
}

export default {
    namespaced: true,
    state,
    mutations
} 