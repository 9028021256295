import { extend, localize } from 'vee-validate';
import zh from 'vee-validate/dist/locale/zh_CN.json';
import { required, email, numeric ,confirmed} from 'vee-validate/dist/rules';
import i18n from '@/lang/index';


// install rules and localization
// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });

//邮箱
extend('email', email);

//必填
extend('required', required);

//数字
extend('numeric', numeric);


extend('phone',{
	validate(value){
		return /^(1(3|4|5|6|7|8|9)[0-9]\d{8})$/.test(value)
	},
	message:'手机号格式不正确'
})
// extend('name',{
// 	validate(value){
// 		return /[\u4e00-\u9fa5]/gm.test(value)
// 	},
// 	message:i18n.t('project.PROMPT_ELEVATOR_NAME5')
// })

//确认
extend('confirmed', confirmed);

localize('zh', zh);
