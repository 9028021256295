import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component:  () => import('@/views/login'),
  },
  {
	path: '/SsoLogin',
	name: 'SsoLogin',
	component: () => import('@/views/login/SsoLogin'),
  },
  {
		path: '/home',
		name: 'home',
		component:  () => import('@/views/layout/index'),
    	children: [
			{
				path: '/user',
				name: 'user',
				component: () => import('@/views/group/user/index'),
			},{
				path: '/role',
				name: 'role',
				component: () => import('@/views/group/roleManagement/index'),
			},{
				path: '/jurisdiction',
				name: 'jurisdiction',
				component: () => import('@/views/group/jurisdiction/index')
			},{
				path: '/organization',
				name: 'organization',
				component: () => import('@/views/group/organization/index')
			},{
				path: '/successCasesList',
				name: 'successCasesList',
				component: () => import('@/views/successCases/list')
			},
			{
				path: "/projectManagement",
				name: "projectManagement",
				component: () => import('@/views/projectManagement/index.vue'),
			},
			{
				path: "/oldElevator",
				name: "oldElevator",
				component: () => import('@/views/oldElevator/index.vue'),
			},
			{
				path: "/statisticalReport",
				name: "statisticalReport",
				component: () => import('@/views/statisticalReport/index.vue'),
			},
    	]
  },
  {
    path: "/config",
    name: "config",
	component:  () => import('@/views/layout/index'),
    	children: [
			{
				path: "dashboard",
				name: "dashboard",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/dashboard"
				}
			},
			{
				path: "data",
				name: "tag_data",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/tag/data"
				}
			},
			{
				path: "unit",
				name: "backdata_unit",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/backdata/unit"
				}
			},
			{
				path: "mapping",
				name: "backdata_mapping",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/backdata/mapping"
				}
			},
			{
				path: "parameter",
				name: "global_parameter",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/parameter"
				}
			},
			{
				path: "calculationUnit",
				name: "global_calculationUnit",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/calculationUnit"
				}
			},
			{
				path: "condition",
				name: "global_condition",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/condition"
				}
			},
			{
				path: "priceFactor",
				name: "global_priceFactor",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/priceFactor"
				}
			},
			{
				path: "ruleTree",
				name: "global_ruleTree",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/ruleTree"
				}
			},
			{
				path: "calculationUnitTree",
				name: "global_calculationUnitTree",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/calculationUnitTree"
				}
			},
			{
				path: "conditionTree",
				name: "global_conditionTree",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/conditionTree"
				}
			},
			{
				path: "parameterTree",
				name: "global_parameterTree",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/global/parameterTree"
				}
			},
			{
				path: "productList",
				name: "product_productList",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/productList"
				}
			},
			{
				path: "configBank",
				name: "product_configBank",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/configBank"
				}
			},
			{
				path: "configBank/:bankId/Param",
				name: "product_configBankParam",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/configBankParam"
				}
			},
			{
				path: "configBank/:bankId/Preview",
				name: "product_configPreview",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/configPreview"
				}
			},
			{
				path: "configBank/:bankId/ExamineBackup",
				name: "product_configBankExamineBackup",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/configBankExamineBackup"
				}
			},
			{
				path: "productTree",
				name: "product_productTree",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/productTree"
				}
			},
			{
				path: "productUserTree",
				name: "product_productUserTree",
				meta:{
					value:'产品'
				},
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/product/productUserTree"
				}
			},
			{
				path: "priceProductList",
				name: "priceProduct_productList",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/productList"
				}
			},
			{
				path: "priceConfigBank",
				name: "priceProduct_configBank",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/configBank"
				}
			},
			{
				path: "priceConfigBank/:bankId/Param",
				name: "priceProduct_configBankParam",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/configBankParam"
				}
			},
			{
				path: "priceConfigBank/:bankId/Preview",
				name: "priceProduct_configPreview",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/configPreview"
				}
			},
			{
				path: "priceConfigBank/:bankId/ExamineBackup",
				name: "priceProduct_configBankExamineBackup",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/configBankExamineBackup"
				}
			},
			{
				path: "priceProductTree",
				name: "priceProduct_productTree",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/productTree"
				}
			},
			{
				path: "priceProductUserTree",
				name: "priceProduct_productUserTree",
				meta:{
					value:'产品'
				},
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/productUserTree"
				}
			},
			{
				path: "releaseBank",
				name: "priceProduct_releaseBank",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/priceProduct/releaseBank"
				}
			},
			{
				path: "basePriceBank/:bankId/priceFactor",
				name: "priceProduct_priceFactor",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "priceProduct/priceFactor"
				}
			},
			{
				path: "calculationUnit",
				name: "record_calculationUnit",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/record/calculationUnit"
				}
			},
			{
				path: "calculateCpq",
				name: "record_calculate_cpq",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/record/calculateCpq"
				}
			},
			{
				path: "calculate",
				name: "record_calculate",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/record/calculate"
				}
			},
			{
				path: "log_audit",
				name: "log_audit",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/log/audit"
				}
			},
    		{
    		    path: "encoderParam",
    		    name: "encoder_encoderParam",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/encoder/encoderParam"
				}
    		},
    		{
    		    path: "codingRule",
    		    name: "encoder_codingRule",
				component: () => import('@/views/layout/components/configIframe.vue'),
				meta: {
					url: "/encoder/codingRule"
				}
    		}
    	]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
