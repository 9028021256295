const leftSidebarModle = localStorage.getItem('leftSidebarModle')
const state = {
    leftSidebarModle: leftSidebarModle
}

const mutations = {
    SetLeftSidebarModle:(state, view) =>{
        state.leftSidebarModle = view
        localStorage.setItem('leftSidebarModle',view)
    }
}

const actions = {
    changeLeftSidebarModle({ commit }, view) {
        commit('SetLeftSidebarModle', view)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  