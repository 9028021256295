import Vue from 'vue'

// 扩展Date对象方法，根据传入的格式返回

/* eslint-disable */
Date.prototype.format = function (format) {
  var o = {
    'M+': this.getMonth() + 1, // month
    'd+': this.getDate(), // day
    'h+': this.getHours(), // hour
    'm+': this.getMinutes(), // minute
    's+': this.getSeconds(), // second
    'q+': Math.floor((this.getMonth() + 3) / 3), // quarter
    'S': this.getMilliseconds() // millisecond
  }
  if (/(y+)/i.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear()+'').substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return format
}

/**
 * 根据formatType格式化日期
 */
Vue.filter('formatDate', (value, formatType) => {
  // 无日期返回空
  if (!value) return ''
  // 为传类型默认yyyy-MM-dd
  if (!formatType) formatType = 'yyyy-MM-dd'
  // TODO后续根据value的不同做处理后再format
  let valStr = value + ''
  // 修复ios下不能new Date 带'-'的日期
  if (valStr.indexOf('-') > -1) {
    valStr.replace(/-/g, '/')
  } else if (valStr.length === 8) {
    valStr = valStr.substr(0, 4) + '/' + valStr.substr(4, 2) + '/' + valStr.substr(6, 2);
  }
  return new Date(valStr).format(formatType)
})
/**
 * 数字格式化方法
 * @param {数值} num
 * @param {保留位数} precision
 * @param {特殊符号} separator
 */
function formatNumber (num, precision, separator) {
    var parts
    // 判断是否为数字
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
      // 把类似 .5, 5. 之类的数据转化成0.5, 5, 为数据精度处理做准, 至于为什么
      // 不在判断中直接写 if (!isNaN(num = parseFloat(num)) && isFinite(num))
      // 是因为parseFloat有一个奇怪的精度问题, 比如 parseFloat(12312312.1234567119)
      // 的值变成了 12312312.123456713
      num = Number(num)
      // 处理小数点位数
      num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString()
      // 分离数字的小数部分和整数部分
      parts = num.split('.')
      // 整数部分加[separator]分隔, 借用一个著名的正则表达式
      parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ''))
  
      return parts.join('.')
    }
    return NaN
  }
  
  /**
   * 格式数字保留两位小数
   */
  Vue.filter('formatNumber', (value, precision, separator) => {
    // 转换为数字
    value = value / 1 || 0
    // 获取保留小数位，默认2
    if (!precision && precision !== 0) precision = 2
    // 千分符或特殊字符
    if (separator) {
      return formatNumber(value, precision, separator)
    } else {
      return formatNumber(value, precision)
    }
  })

Vue.filter( 'formatDate',(value)=>{
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? ('0' + MM) : MM;
  let d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  let h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  let m = date.getMinutes();
  m = m < 10 ? ('0' + m) : m;
  let s = date.getSeconds();
  s = s < 10 ? ('0' + s) : s;
  return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
})