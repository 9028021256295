import request from '@/api/index.js'


//登录
export function AccountLogin(data) {
    return request({
        url: '/api/Account/Login',
        method: 'post',
        data
    })
}
//登录
export function AccountSsoLogin(data) {
    return request({
        url: 'api/Account/Login',
        method: 'get',
        params: data
    })
}

//登录验证
export function AccountLoginCheck(data) {
    return request({
        url: '/api/Account/LoginCheck',
        method: 'post',
        data
    })
}

//退出登录
export function AccountLogout(data) {
    return request({
        url: '/api/Account/Logout',
        method: 'get',
        data
    })
}

//忘记密码
export function ForgetPassword(data) {
    return request({
        url: '/api/Account/FindPassword',
        method: 'post',
        data
    })
}

//重置密码
export function ResetPassword(data) {
    return request({
        url: '/api/Account/ResetPassword',
        method: 'post',
        data
    })
}
