import axios from 'axios'
import { Message ,Loading} from 'element-ui'
// import store from '@/store'
import { COOKIES_ADMIN_TOKEN } from '@/config/cookies/index'
import { getToken } from '@/utils/cookies/index'
import NProgress from 'nprogress' // js实现进度条效果
NProgress.configure({ showSpinner: false, minimum: 0.2, easeing: 'swing', speed: 1000, trickleRate: 0.2 });

let loading = null


axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.timeout = 50000;
let loadingInstance={};

let requestList=new Set()//存储请求url

axios.interceptors.request.use(
    config => {
        // if(config.url=="/api/Integration/ThinConfig" || config.url=="/api/Elevator/ElevatorConfiguration"){
        //     loading = Loading.service({
        //         lock: true,
        //         text: '',
        //         spinner: '',
        //         background: 'rgba(0, 0, 0, 0)'
        //     });
        // }
        config.cancelToken=new axios.CancelToken(e=>{
            let url=config.method+":"+config.url;
            
            if(requestList.has(url)&&('post'===config.method||'put'===config.method)&&(config.url.split('?')[0]!='/-param/api/Integration/ThinConfig')){
                e(`${location.host}${config.url}---重复请求被中断`)
            }
            else if('post'===config.method||'put'===config.method){
                requestList.add(url)
                //requestList.add(config.url)
            }
        })
        let requestMark  = `${config.method}_${config.url}`
        if(Object.prototype.hasOwnProperty.call(loadingInstance, requestMark)){
            requestMark += new Date().getTime()
        }
        // if(!config.params || !config.params.noloading) {
        //     if(!config.isLoading){
        //         loadingInstance[requestMark] = Loading.service({
        //             fullscreen: true,
        //             text: "数据加载中",
        //             spinner: "el-icon-loading",
        //             customClass: "myloadingClass" ,
        //             target: document.querySelector(`.${config.loadingArea}`)
        //         });
        //     }
        // }
        NProgress.start();
        // do something before request is sent
        let token = getToken(COOKIES_ADMIN_TOKEN)
        if (token) {
        //   let each request carry token
        //   ['X-Token'] is a custom headers key
        //   please modify it according to the actual situation
            config.headers['Authorization'] = token
        }
        config.requestMark = requestMark
        return config
    },
    error => {
        NProgress.done();
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
axios.interceptors.response.use(
    response => {
        loading ? loading.close() : null;

        setTimeout(() => {
            requestList.delete(response.config.method+":"+response.config.url)
            //requestList.delete(response.config.url)
        }, 1000);//相同请求不能在1秒内重复发送

        NProgress.done()
        const res = response.data
        if (response && (response.status === 200 || response.status === 304)) {
            // if the custom code is not 20000, it is judged as an error.
            if (!res.success && res && response.config.responseType != 'blob') {
                Message({
                    message: res.errorMsg || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
            }
        }

        return res
    },
    error => {
        loading ?loading.close(): null;
        NProgress.done()
        if (error.response) {
            let statusText = error.response.statusText
            switch (error.response.status) {
                case 401:
                    Message({
                        message: statusText || '未授权',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    location.replace('/')
                    break
                case 403:
                    Message({
                        message: statusText || '权限不足，拒绝请求',
                        type: 'error',
                        duration: 5 * 1000
                    })
                    break
                default:
                    Message({
                        message: statusText,
                        type: 'error',
                        duration: 5 * 1000
                    })
            }
        }

        if(error.config&&error.config.url){
            requestList.delete(error.config.url)
        }
        return Promise.reject(error)
    }
)

export default axios
