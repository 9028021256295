const state = {
    paginationData:{}
}

const mutations={
    setPaginationData(state,paginationData){
        state.paginationData=paginationData;
    } 
}

export default {
    namespaced: true,
    state,
    mutations
} 