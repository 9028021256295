export default {
    L_USERNAME:'用户名',
    L_EMAIL:'邮箱',
    L_NI_EMAIL:'NI邮箱',
    L_MODE_EMAIL:'MOD邮箱',
    L_WORKNUMBER:'销售员工号',
    L_PHONE:'手机号',
    L_ADDRESS:'地址',
    L_PERMISSIONS:'权限',
    L_NAME:'名称',
    L_CODE:'代号',
    L_DESC:'描述',
    L_USER_LIST:'用户列表',
    L_USER_TYPE:'类型',
    L_ADD_ROLE:'新增角色',
    L_ADD_GROUP:'新增组织',
    L_ROLE_LIST:'角色列表',
    L_GROUP_LIST:'组织列表',
    L_VISIBLE:'是否可见',
    L_PASSWORD:'密码',
    L_LOGIN:'登录',
    L_REGIS_EMAIL:'注册时邮箱',
    L_IS_ADCOUNT:'是否AD账号',
    L_IS_QUICKACOUNT:'是否为通力账户',

    L_ROLE_TYPE:'角色类型',

    ROLE_TYPE_1:'管理员',
    ROLE_TYPE_2:'总部',
    ROLE_TYPE_3:'销售支持',
    ROLE_TYPE_4:'销售经理/地区经理',
    ROLE_TYPE_5:'销售',
    ROLE_TYPE_6:'代理商',
    ROLE_TYPE_7:'分公司经理',
    ROLE_TYPE_8:'销售&销售支持',

    L_ADD_PERMISSION:'新建权限',

    PERMISSION_TYPE_1:'模块权限',
    PERMISSION_TYPE_2:'功能权限',
    PERMISSION_TYPE_3:'字段权限',
    PERMISSION_TYPE_4:'系统权限',

    L_TYPE:'类型',

    L_PERMISSIONS_VERIFICATION_INFO:'请配置权限',
    L_PERMISSIONS_CONFIG:'权限配置',

    L_SALESMANAGER_USER_LIST:'挂载经理列表',
    L_SALESMANAGER_USER:'挂载经理名',

    
    L_SELECT_SALESMANAGER:'选择挂载经理',
    L_SELECT_SALESMANAGER_INFO:'请先选择挂载经理',

    L_BATCH_IMPORT_BRANCH_EMAIL:'导入分公司邮箱',
    L_BATCH_EXPORT_BRANCH_EMAIL:'导出分公司邮箱',

    L_REALNAME:'姓名',
    L_AGENTNAME:'代理商/经销商名称',

    L_IMPORT_ROLE_PERMISSION:'导出角色权限',

    L_EXPORT_USERS_INFO:'导出用户信息',
    L_IMPORT_USERS_INFO:'导入用户信息',

    L_CHANGE_PASSWORD:'修改密码',
    L_FORGET_PASSWORD:'忘记密码',
    L_RESET_PASSWORD:'重置密码',
    L_RESET_SUCCESS:'重置密码成功',
    L_PASSWORD_LENGTH:'新密码长度不能小于6位',
    L_OLD_PASSWORD:'原密码',
    L_NEW_PASSWORD:'新密码',
    L_CONFIRM_PASSWORD:'确认密码',
    L_PASSWORD_ERROR:'原密码输入不正确',
    L_EMAIL_ERROR:'邮箱格式不正确',

    L_USER_INFO:'个人信息',
    L_REPEAT_USERSINFO:'导入结果',
    L_IMPORT_ALL_SUCCESS:'全部导入成功',
    L_IMPORT_SUCCESS:'导入成功',
    L_IMPORT_ERROR:'失败信息'
}