
import { getToken,setToken,removeToken } from '@/utils/cookies'
import { COOKIES_ADMIN_TOKEN } from '@/config/cookies'
import { AccountLogin,AccountLogout,AccountSsoLogin } from '@/api/userAPI/login'

const userInformation = localStorage.getItem('userInformation')
const personalConfigInfo = localStorage.getItem('personalConfigInfo')
const state = {
    token: getToken(COOKIES_ADMIN_TOKEN),
    userInformation: userInformation ? JSON.parse(userInformation) : {},
    personalConfigData:personalConfigInfo ? JSON.parse(personalConfigInfo) : {},//配置信息
}

const mutations = {
    SET_TOKEN : (state,token) => {
        state.token = token
    },
    USER_INFORMATION : (state,userInformation) => {
        state.userInformation = userInformation
    },
    setPersonalConfigData:(state, personalConfigData) =>{
        state.personalConfigData = personalConfigData;
    },
}

const actions = {
    AccountLogin({commit},userInfo) {
        return new Promise((resolve,reject) => {
            AccountLogin(userInfo).then(res=>{
                if(res.success) {
                    const { user } = res.data
                    commit('SET_TOKEN',res.data.tokenType+' '+ res.data.token)
                    commit('USER_INFORMATION', user)
                    setToken(COOKIES_ADMIN_TOKEN,res.data.tokenType+' '+ res.data.token)
                    localStorage.setItem('userInformation',JSON.stringify(user))
                    resolve()
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    AccountSsoLogin({commit},userInfo) {
        return new Promise((resolve,reject) => {
            AccountSsoLogin(userInfo).then(res=>{
                if(res.success) {
                    const { user } = res.data
                    commit('SET_TOKEN',res.data.tokenType+' '+ res.data.token)
                    commit('USER_INFORMATION', user)
                    setToken(COOKIES_ADMIN_TOKEN,res.data.tokenType+' '+ res.data.token)
                    localStorage.setItem('userInformation',JSON.stringify(user))
                }
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },
    AccountLogout({commit}) {
        return new Promise((resolve,reject) => {
            AccountLogout().then(()=>{
                removeToken(COOKIES_ADMIN_TOKEN)
                localStorage.removeItem('userInformation')
                localStorage.removeItem("activityModule")
                commit('USER_INFORMATION', {})
                commit('SET_TOKEN', '')

                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    setPersonalConfigData({commit}){
        return new Promise((resolve,reject) => {
            SettingConfig().then(res=>{
                localStorage.setItem(COOKIES_PERSONAL_CONFIG_IFON,JSON.stringify(res.data) )
                commit('setPersonalConfigData', res.data);
                resolve(null)
            }).catch(error => {
                reject(error)
            })
        })
    },
}


export default{
    namespaced: true,
    state,
    mutations,
    actions
}