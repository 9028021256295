<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    
  } 
}
</script>
<style lang="scss">
@font-face {
  font-family: Inter-Regular;
  src: url('./assets/Inter-Regular.ttf');
}
@font-face {
  font-family: Inter-SemiBold;
  src: url('./assets/Inter-SemiBold.ttf');
}
@font-face {
  font-family: NotoSansSC-Bold;
  src: url('./assets/NotoSansSC-Bold.otf');
}
@font-face {
  font-family: NotoSansSC-Regular;
  src: url('./assets/NotoSansSC-Regular.otf');
}
@font-face {
  font-family: KONE-Information;
  src: url('./assets/KONE Information.otf');
}
#app {
    font-family: KONE-Information,NotoSansSC-Bold,NotoSansSC-Regular,Inter-Regular,Inter-SemiBold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
  
    //解决表格表头和单元格线对不齐
    .el-table th { display: table-cell!important; }

    .iconfont {
      cursor: pointer;
    }
  
}

</style>

<style lang="scss">
    /* fade-transform */
    .fade-transform-leave-active,
    .fade-transform-enter-active {
      transition: all .5s;
    }

    .fade-transform-enter {
      opacity: 0;
      transform: translateX(-30px);
    }

    .fade-transform-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }
    #nprogress .bar {
      background: #207ddc !important; //自定义颜色
      height: 4px !important;
    }
    .el-message-box__btns {
    text-align: center;
    // 交换 确定 和 取消 按钮的位置
    .el-button:nth-child(1) {
        float: right;
    }
    .el-button:nth-child(2) {
        float: left;
        margin-left: 65%;
    }
    .el-tabs__item {
      outline: none !important;
    }
}

</style>
