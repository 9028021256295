import i18n from '@/lang/index'
import * as PERMISSIONCODE from './permissionCode'
export const PERMISSION={
    //类型
    Type:{
        1:i18n.t('users.PERMISSION_TYPE_1'),
        2:i18n.t('users.PERMISSION_TYPE_2'),
        3:i18n.t('users.PERMISSION_TYPE_3'),
        4:i18n.t('users.PERMISSION_TYPE_4'),
    },
}

export {
    PERMISSIONCODE
}