const COOKIES_ADMIN_TOKEN = 'Cookies-Admin-Token'  //Token标记
// const COOKIES_USER_INFORMATION = 'Cookies-User-Information' //用户信息标记
// const COOKIES_TAGSVIEW_LISTS = 'Cookies-TagsView-Lists' //tagsView数据
const COOKIES_LEFT_SIDEBAR_MODULE = 'Cookies-leftSidebarModle'//进入模块显示对应的所属模块标记

export {
    COOKIES_ADMIN_TOKEN,
    // COOKIES_USER_INFORMATION,
    COOKIES_LEFT_SIDEBAR_MODULE,
    // COOKIES_TAGSVIEW_LISTS
}