const getters = {
    leftSidebarModle:state=>state.leftSidebarModle.leftSidebarModle,
    paginationData:state=>state.paginationQuery.paginationData,
    elevatorPaginationData:state=>state.elevatorPaginationQuery.elevatorPaginationData,
    versionPaginationData:state=>state.versionPaginationQuery.versionPaginationData,
    token: state => state.user.token,
    userInformation: state => state.user.userInformation,
    sidebar:state => state.sidebar.sidebar,
    multilingualConfigData:state => state.multilingualConfig.multilingualConfigData,
}
export default getters
