export default {
    SLOGAN: '让城市更美好',
    BACKGROUNDMANAGEMENT: '后台管理',
    L_HOME: '主页',
    MAININTERFACE: '主界面',
    MESSAGE: '消息',
    PERSONALINFO: '个人信息',
    LOGOUT: '退出登录',
    LOGIN_LOGO_TEXT:'通力中国新梯快速报价工具',
    L_FORMALSTATUS: '同步状态',
    L_DOWNLOADCALCULATIONEXCEL: '计价excel下载',
    L_ELEVATORDRAWING: '扶梯出图',
    L_DIGIPARA: 'Digipara出图',
    L_TM110S: 'TM110S出图',
    L_CLOSE: '关闭',
    L_CLOSE_LEFT_TABS: '关闭左侧',
    L_CLOSE_RIGHT_TABS: '关闭右侧',
    L_CLOSE_ALL_TABS: '关闭所有',
    L_CLOSE_OTHER_TABS: '关闭其它',
    LBATCHOPEN: '批量打开',
    L_STATUS: '状态',
    L_EDIT: '修改',
    L_DETAILS: '详情',
    L_PRICING_DATA: '计价数据',
    L_APPROVAL_RECORD: '审批记录',
    L_LAST_VALID_RATE:'最后修改时间',
    L_PAYMENT_NODE: '付款节点',
    L_DELETE: '删除',
    L_ADD: '新建',
    L_MORE_FILTERS: '更多筛选',
    L_PERFORM_FILTERING: '执行筛选',
    L_CLEAR_FILTER: '清除筛选',
    L_JOIN_COMPARE: '加入对比',
    L_VIEW_COMPARE: '查看对比',
    L_RELEASE_PRICE: '释放价格',
    L_REQUEST_GROUP_PRICE: '请求组价',
    L_CANCEL_GROUP_PRICE: '取消组价',
    L_SUBMIT: '提交',
    L_BATCH_ADD: '批量新建',
    L_RELEASE: '发布',
    L_CODE: '代号',
    L_UNDO: '撤销',
    L_SEARCH: '搜索',
    L_QUERY: '查询',
    L_CONFIRM: '确认',
    L_CONFIRM1: '保存并退出',
    L_CHECK: '校验并退出',
    L_CANCEL: '取消',
    L_ACTIONS: '操作',
    L_SAVE: '保存',
    L_CALCULATION: '计算',
    L_NO_CHANGE_CALCULATION: '销售价不变计算',
    L_DOWNLOAD: '导出',
    L_REMOVED: '移除',
    L_GO_BACK: '返回',
    L_UPLOAD: '导入',
    L_BASIC_INFO: '项目信息',
    L_PERMISSION_INFO: '权限信息',
    L_GROUP_INFO: '组织信息',
    L_EDITION: '报价单列表',
    // L_BASIC_INFO: '基本信息',
    L_P_E_T_C: '请输入内容',
    L_eleType: '配置类型',
    L_TPVersion: 'TP版本',
    L_SUCCESSFUL_MESSAGE: '操作成功',
    L_SET_NONSTANDARD_STATUS: '设置非标描述',
    L_DELETE_CONTINUE: '删除此数据后将无法恢复，是否继续？', //删除此数据后将无法恢复，是否继续？
    L_PROMPT_VERIFICATION_FAILED_INFO: '请按规则完善字段信息',
    L_EDITING_WARNING: '正在编辑项目：',
    L_EDITTABLEHEADWR: '编辑列选项',
    L_RESERVED: '预留明细',
    L_BUILDINGGROUP: '建筑群组',
    L_INQUIRY_SHEET: '电子询价单',
    L_INQUIRY_SHEET1: '新建电梯询价单',
    L_INQUIRY_SHEET2: '修改电梯询价单',
    L_INQUIRY_SHEET3: '新建扶梯询价单',
    L_INQUIRY_SHEET4: '修改扶梯询价单',
    L_FILTER: '筛选预留项',
    L_FORECAST: '价格预测',
    L_FORECAST_PROJECTCHARACTERISTICS: '项目特性',
    L_RESERVED_WARN: '请选择一条需要设置的项目',
    L_RESERVED_WARN2: '请选择一条需要计算的项目',
    L_ADACCOUNT:'请输入通力用户名',
    L_ADPASSWORD:'请输入通力密码',
    L_ADACCOUNT_LOGIN:'通力账号登录',
    L_EXTERNAL_ACCOUNT_LOGIN:'外部账号登录',

    L_PROJECT_LIST: '项目列表',
    L_ELEVATOR_LIST: '电梯列表',
    L_VERSTION_LIST: '报价单列表',
    L_ORDER_LIST: '订单列表',

    L_COPY: '复制',
    L_COPY1: '从其他项目复制来',
    L_CONVERT: '项目转换',
    L_COPY_CONTINUE: '是否确认要进行复制操作？',
    L_SET_NONSTANDARD_STATUS_USER_WARN: '请选择至少一条需要设置的项目',
    L_SET_CALCULATION_USER_WARN: '请选择至少一条需要计算的项目',
    L_SET_CALCULATION_USER_WARN1: '请选择一条需要预测的项目',
    L_SET_CALCULATION_USER_WARN2: '请选择一条项目',
    L_SET_NONSTANDARD_STATUS_CONTINUE: '是否确认要进行设置非标描述操作？',


    L_TRANSFER: '项目转移',
    L_TRANSFER_USER: '转移用户',
    L_TRANSFER_USER_SELECT: '请选择转移用户',
    L_TRANSFER_VALID_INFO: '转移用户不能为空',
    L_TRANSFER_CHOOSE_ALL: '转移全部项目',
    L_TRANSFER_VALID_CHOOSE_INFO: '转移全部项目不能为空',
    L_TRANSFER_CHOOSE_WARN: '请选择至少一条需要转移的项目',
    L_TRANSFER_USER_WARN: '请选择目标转移用户!',
    L_TRANSFER_SUCCESS: '转移成功!',
    L_REGION: '区域',
    L_BRANCH: '分公司',
    L_SEARCH_PROJECT: '项目搜索',

    L_SHARE_READ: '请选择只读共享(销售支持)用户',
    L_SHARE_EDIT: '请选择读写共享(销售支持)用户',
    L_SHARE_SALE: '请选择共享(销售)用户',
    L_SHARE_USER_INFO: '共享用户',
    L_SHARE_USER_VALID_INFO: '共享用户不能为空',
    L_SHARE_CONFIRM_MESSAGE: '是否撤回项目共享用户{parameter}',
    L_SHARE_CANCEL_SUCCESS: '撤回成功',
    L_SHARE_CANCEL_RETURN: '已取消撤回',
    L_SHARE_SUCCESS: '共享成功!',

    L_LOCK: '加锁',
    L_UNLOCK: '解锁',
    L_LOCK_SUCCESS: '锁定成功!',
    L_UNLOCK_SUCCESS: '解锁成功!',
    L_UNLOCK_MNETION: '该项目未被锁定!',
    L_UNLOCK_CONTINUE: '该项目:{project} 已被用户:{user} 锁定,是否强制解锁?',
    L_PRICE_APPROVE: '价格审批',
    L_APPROVAL_WITHDRAWAL: '审批撤回',
    BOOL_TYPE_1: '是',
    BOOL_TYPE_2: '否',

    L_VISIBLE_ONLY: '该版本状态下只能查看，不可修改信息等操作',
    L_SELECT_AGENT: '选择代理商',

    L_IMPORT: '导入',
    L_IMPORT1: '导入价格',
    L_EXPORT: '导出',
    L_EXPORT1: '导出含税价格',
    L_EXPORT2: '导出技术规格',
    L_EXPORT3: '导出安装计价单',
    L_ATTACHMENT: '附件',
    L_TURNONSORTING: '开启排序',
    L_TURNOFFSORTING: '关闭排序',
    L_RESULT: '结果',
    L_ATTACHMENT_EMPTY_INFO: '文件不能为空',
    L_IMPORT_CHOOSER_WARN: '请选择一条数据导入',
    L_TABLEHEADEROPTION: '列选项',

    L_DRAWINGSWITCH: '图纸开关',
    L_DRAWINGEMAIL: '邮件',
    L_INSPECT: '检查',
    L_EXPORTDETAIL: '导出详细信息',
    L_TRANSMISSIONKTOC: '传输KTOC',
    L_EXPORTKTOC: '导出KTOC数据',
    
    
    L_UPLOAD_FILE: '上传',
    L_UPLOAD_FILEList: '附件列表',

    L_SELECT_LOGIN_ROLE: '选择登录角色',
    L_LOGIN_ROLE: '登录角色',
    L_SELECT_LOGIN_ROLE_MESSAGE: '请选择登录角色',

    L_RESETPASSWORD_EMAIL: '重置密码邮件已发送至邮箱，请注意查收',

    L_LOGIN_USER_NAME:'请输入用户名',
    L_LOGIN_USER_PASSWORD:'请输入密码',
    L_IMPORE_EXCEL:'请导入excel文件',
    L_IMPORE_EXCEL_NULL:'导入数据不能为空',

    P_CLOSE:'关 闭',
    P_VALUATION_DATA: "计价数据",
    P_IMPORT: "数据导入",
    P_CONFIGURATION: "配置单",
    P_COLUMNOTIONS: "列选项",
    P_RESERVEDDETAILS: "安装预留",

    L_PHONEUMBER_SUCCESS:'手机号修改成功',
    L_PHONEUMBER_NULL:'手机号不能为空',
    L_EDIT_PHONENUMBER:'修改手机号',

    CONFIG_MANAGEMENT: '配置管理',
    MAIN_MENU: '主菜单'
}